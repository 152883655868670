.dynamic-notification-animation {
  animation: fadeIn 0.5s;
}

.dynamic-notification-animation-lite {
  animation: fadeInLite 0.5s;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes fadeInLite {
  0% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}
